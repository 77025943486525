<template lang="pug">
main(class="lg:mt-10")
  .mb-4.w-full.max-w-screen-md.mx-auto.relative(class="md:mb-0", style="height: 24em")
    .absolute.left-0.bottom-0.w-full.h-full.z-10(
      style="background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7))"
    )
    img.absolute.left-0.top-0.w-full.h-full.z-0.object-cover(:src="post.image")
    .p-4.absolute.bottom-0.left-0.z-20
      a.px-4.py-1.bg-black.text-gray-200.inline-flex.items-center.justify-center.mb-2(
        href="#"
      )
        | {{ post.categories.join(', ') }}
      h2.text-3xl.font-semibold.text-gray-100.leading-tight
        | {{ post.title }}
      .flex.mt-3
        img.h-10.w-10.rounded-full.mr-2.object-cover.object-top(
          :src="require('img/about-img.jpg')"
        )
        div
          p.font-semibold.text-gray-200.text-sm {{ post.author }}
          p.font-semibold.text-gray-400.text-xs {{ post.date }}
  .px-2.mt-12.text-gray-700.max-w-screen-md.mx-auto.leading-relaxed(
    class="xs:px-5 lg:px-0 lg:text-lg"
  )
    div(v-html="post.text")
</template>

<script>
// Data
import Posts from "@/posts.json";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          property: "og:url",
          content: `${window.location.origin}/post/${this.post.id}`,
        },
        { property: "og:type", content: "website" },
        { property: "og:title", content: this.post.title },
        { property: "og:description", content: this.post.text },
        { property: "og:image:secure", content: this.post.image },
      ],
    };
  },
  computed: {
    post() {
      return Posts.data.find((post) => post.id === this.id);
    },
  },
};
</script>
